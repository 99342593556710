import { aiToolsIds, aiTools } from '@/helpers/ai-tools.js';

export default {
  name: 'AiToolsMixin',
  data() {
    return {
      aiToolsTranslations: {
        [aiToolsIds.VIDEO_SCRIPTWRITER]: {
          title: this.$t('ai_tools.video_scriptwriter'),
          desc: this.$t('ai_tools.video_scriptwriter_desc'),
          descWbr: this.$t(this.$wbr('ai_tools.video_scriptwriter_desc')),
          img: 'https://static.moele.me/img/studio-ai/tools/video-scriptwriter.240731.png?auto=format&w=267',
          imgResponsive: 'https://static.moele.me/img/studio-ai/tools/video-scriptwriter-m.240731.png?auto=format&w=345',
          price: null,
        },
        [aiToolsIds.FACE_SWAP]: {
          title: this.$t('ai_tools.face_swap'),
          desc: this.$t('ai_tools.face_swap_desc'),
          descWbr: this.$t(this.$wbr('ai_tools.face_swap_desc')),
          img: 'https://static.moele.me/img/studio-ai/tools/face-swap.240731.png?auto=format&w=267',
          imgResponsive: 'https://static.moele.me/img/studio-ai/tools/face-swap-m.240731.png?auto=format&w=345',
          price: '6',
        },
        [aiToolsIds.BACKGROUND_REMOVER]: {
          title: this.$t('ai_tools.background_remover'),
          desc: this.$t('ai_tools.background_remover_desc'),
          descWbr: this.$t(this.$wbr('ai_tools.background_remover_desc')),
          img: 'https://static.moele.me/img/studio-ai/tools/background-remover.240731.png?auto=format&w=267',
          imgResponsive: 'https://static.moele.me/img/studio-ai/tools/background-remover-m.240731.png?auto=format&w=345',
          price: '4',
        },
        [aiToolsIds.STYLE_TRANSFER]: {
          title: this.$t('ai_tools.style_transfer'),
          desc: this.$t('ai_tools.style_transfer_desc'),
          descWbr: this.$t(this.$wbr('ai_tools.style_transfer_desc')),
          img: 'https://static.moele.me/img/studio-ai/tools/style-transfer.240731.png?auto=format&w=267',
          imgResponsive: 'https://static.moele.me/img/studio-ai/tools/style-transfer-m.240731.png?auto=format&w=345',
          price: '5',
        },
        [aiToolsIds.IMAGE_VARIATIONS]: {
          title: this.$t('ai_tools.image_variations'),
          desc: this.$t('ai_tools.image_variations_desc'),
          descWbr: this.$t(this.$wbr('ai_tools.image_variations_desc')),
          img: 'https://static.moele.me/img/studio-ai/tools/image-variation.240918.png?auto=format&w=267',
          imgResponsive: 'https://static.moele.me/img/studio-ai/tools/image-variation.240918.png?auto=format&w=345',
          price: '6',
        },
      },
    };
  },
  computed: {
    aiToolsIds() {
      return aiToolsIds;
    },
    aiTools() {
      return aiTools.map(tool => ({
        ...tool,
        ...this.aiToolsTranslations[tool.id],
      }));
    },
  },
};
