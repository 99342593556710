import {
  faText,
  // faFaceViewfinder,
  faEraser,
  faBrush,
  faImages,
} from '@fortawesome/pro-regular-svg-icons';

export const aiToolsIds = {
  VIDEO_SCRIPTWRITER: 'video_scriptwriter',
  FACE_SWAP: 'face_swap',
  BACKGROUND_REMOVER: 'background_remover',
  STYLE_TRANSFER: 'style_transfer',
  IMAGE_VARIATIONS: 'image_variations',
};

export const aiTools = [
  {
    id: aiToolsIds.VIDEO_SCRIPTWRITER,
    link: '/video-scriptwriter',
    icon: faText,
  },
  // {
  //   id: aiToolsIds.FACE_SWAP,
  //   link: '/face-swap',
  //   icon: faFaceViewfinder,
  //   model: 'image_face_swap',
  // },
  {
    id: aiToolsIds.BACKGROUND_REMOVER,
    link: '/background-remover',
    icon: faEraser,
    model: 'image_remove_background',
  },
  {
    id: aiToolsIds.STYLE_TRANSFER,
    link: '/style-transfer',
    icon: faBrush,
    model: 'style_',
  },
  {
    id: aiToolsIds.IMAGE_VARIATIONS,
    link: '/image-variations',
    icon: faImages,
    model: 'image_variation',
  },
];
