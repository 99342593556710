//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  faAngleDown,
} from '@fortawesome/pro-light-svg-icons';

import AiToolsMixin from '@/components/mixins/ai-tools.mixin.js';

export default {
  name: 'StudioAiDropdown',
  mixins: [AiToolsMixin],
  props: {
    variant: {
      type: String,
    },
    onShow: Function,
  },
  data() {
    return {
      faAngleDown,
    };
  },
  computed: {
  },
};
